import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { PurchasingSupplierPurchasingMetaUiMentionModule } from '@ts/purchasing/supplier-purchasing-meta/ui-mention';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { PurchasingSupplierPurchasingMetaFormFieldComponent } from './purchasing-supplier-purchasing-meta-form-field/purchasing-supplier-purchasing-meta-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'supplier-purchasing-meta',
          component: PurchasingSupplierPurchasingMetaFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    PurchasingSupplierPurchasingMetaUiMentionModule,
  ],
  declarations: [PurchasingSupplierPurchasingMetaFormFieldComponent],
  exports: [PurchasingSupplierPurchasingMetaFormFieldComponent],
})
export class PurchasingSupplierPurchasingMetaFormFeatureFieldModule {}
