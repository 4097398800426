import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InventoryReplenishmentChannelMentionUiComponent } from './inventory-replenishment-channel-mention-ui/inventory-replenishment-channel-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [InventoryReplenishmentChannelMentionUiComponent],
  exports: [InventoryReplenishmentChannelMentionUiComponent],
})
export class InventoryReplenishmentChannelUiMentionModule {}
