import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FacilityUiMentionModule } from '@ts/facility/ui-mention';
import { SupplierUiMentionModule } from '@ts/supplier/ui-mention';

import { PurchasingSupplierPurchasingMetaMentionUiComponent } from './purchasing-supplier-purchasing-meta-mention-ui/purchasing-supplier-purchasing-meta-mention-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SupplierUiMentionModule,
    FacilityUiMentionModule,
  ],
  declarations: [PurchasingSupplierPurchasingMetaMentionUiComponent],
  exports: [PurchasingSupplierPurchasingMetaMentionUiComponent],
})
export class PurchasingSupplierPurchasingMetaUiMentionModule {}
