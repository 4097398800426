import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ts-inventory-replenishment-channel-table-header-ui',
  templateUrl:
    './inventory-replenishment-channel-table-header-ui.component.html',
  styleUrls: [
    './inventory-replenishment-channel-table-header-ui.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryReplenishmentChannelTableHeaderUiComponent {}
