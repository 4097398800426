import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { OrderStatus } from '@ts/order/shared/util-core';
import { OrderStatusI18nService } from '@ts/order/shared/util-status-i18n';
import { IonColor } from '@ts/shared/util-ionic';

@Component({
  selector: 'ts-order-status-ui',
  templateUrl: './order-status-ui.component.html',
  styleUrls: ['./order-status-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusUiComponent implements OnInit {
  @Input() orderStatus!: OrderStatus;

  orderStatusColor?: IonColor;

  ORDER_STATUS_TO_COLOR: { [orderStatus in OrderStatus]?: IonColor } = {
    Cancelled: 'danger',
  };

  orderStatusI18n$!: Promise<string>;

  constructor(private orderStatusI18nService: OrderStatusI18nService) {}

  ngOnInit() {
    this.orderStatusI18n$ = this.orderStatusI18nService.translate$(
      this.orderStatus,
    );
    this.orderStatusColor = this.ORDER_STATUS_TO_COLOR[this.orderStatus];
  }
}
