import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ts-purchasing-supplier-purchasing-meta-table-header-ui',
  templateUrl:
    './purchasing-supplier-purchasing-meta-table-header-ui.component.html',
  styleUrls: [
    './purchasing-supplier-purchasing-meta-table-header-ui.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingSupplierPurchasingMetaTableHeaderUiComponent {}
