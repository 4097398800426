<ts-pagination-infinite-table [pagingService]="orderPagingStaffService">
  <ts-order-table-header-staff-ui
    slot="header"
  ></ts-order-table-header-staff-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-order-table-body-staff-ui
      [orders]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-order-table-body-staff-ui>
  </ng-template>
</ts-pagination-infinite-table>
