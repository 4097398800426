<tr *ngFor="let supplierPurchasingMeta of supplierPurchasingMetas">
  <td>
    <ts-supplier-mention-ui
      [supplier]="supplierPurchasingMeta.supplier"
    ></ts-supplier-mention-ui>
  </td>
  <td>
    <ts-facility-mention-ui
      [facility]="supplierPurchasingMeta.facility"
    ></ts-facility-mention-ui>
  </td>
  <td>
    {{ supplierPurchasingMeta.purchasing_cycle_days }}
  </td>
  <td>
    {{ supplierPurchasingMeta.lead_time_days }}
  </td>
  <td>
    <ts-timestamp-ui
      [timestamp]="supplierPurchasingMeta.date_edited_timestamp"
    ></ts-timestamp-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: supplierPurchasingMeta }"
    >
    </ng-template>
  </td>
</tr>
