<ts-modal-ui title="Pilih Inventory Replenishment Channel">
  <ts-page-card-ui>
    <ts-choices-action-template-ui
      [initial]="initial"
      (selected)="inventoryReplenishmentChannelSelected($event)"
    >
      <ng-template #choicesTemplate let-actionTemplate="actionTemplate">
        <ts-inventory-replenishment-channel-list-infinite
          [actionTemplate]="actionTemplate"
        ></ts-inventory-replenishment-channel-list-infinite>
      </ng-template>
    </ts-choices-action-template-ui>
  </ts-page-card-ui>
</ts-modal-ui>
