import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SupplierPurchasingMeta } from '@ts/purchasing/shared/util-core';
import { PurchasingSupplierPurchasingMetaSelectModalComponent } from '@ts/purchasing/supplier-purchasing-meta/action/feature-select';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-purchasing-supplier-purchasing-meta-form-field',
  templateUrl:
    './purchasing-supplier-purchasing-meta-form-field.component.html',
  styleUrls: [
    './purchasing-supplier-purchasing-meta-form-field.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingSupplierPurchasingMetaFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'supplierPurchasingMeta';
  initial?: SupplierPurchasingMeta;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<SupplierPurchasingMeta>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: PurchasingSupplierPurchasingMetaSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
