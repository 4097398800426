import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { SupplierPurchasingMeta } from '@ts/purchasing/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-purchasing-supplier-purchasing-meta-table-body-ui',
  templateUrl:
    './purchasing-supplier-purchasing-meta-table-body-ui.component.html',
  styleUrls: [
    './purchasing-supplier-purchasing-meta-table-body-ui.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingSupplierPurchasingMetaTableBodyUiComponent {
  @Input() supplierPurchasingMetas!: readonly SupplierPurchasingMeta[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<
    ActionTemplateContext<SupplierPurchasingMeta>
  >;
}
