import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InventoryReplenishmentChannel } from '@ts/inventory/replenishment/shared/util-core';

@Component({
  selector: 'ts-inventory-replenishment-channel-mention-ui',
  templateUrl: './inventory-replenishment-channel-mention-ui.component.html',
  styleUrls: ['./inventory-replenishment-channel-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryReplenishmentChannelMentionUiComponent {
  @Input() inventoryReplenishmentChannel!: InventoryReplenishmentChannel;
}
