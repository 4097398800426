import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { SupplierPurchasingMeta } from '@ts/purchasing/shared/util-core';
import { SupplierPurchasingMetaPagingService } from '@ts/purchasing/supplier-purchasing-meta/list/data-access';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-purchasing-supplier-purchasing-meta-list-infinite',
  templateUrl:
    './purchasing-supplier-purchasing-meta-list-infinite.component.html',
  styleUrls: [
    './purchasing-supplier-purchasing-meta-list-infinite.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingSupplierPurchasingMetaListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<
    ActionTemplateContext<SupplierPurchasingMeta>
  >;

  constructor(
    public supplierPurchasingMetaPagingService: SupplierPurchasingMetaPagingService,
  ) {}
}
