import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { OrderPagingStaffService } from '@ts/order/list/data-access';
import { Order } from '@ts/order/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-order-list-infinite-staff',
  templateUrl: './order-list-infinite-staff.component.html',
  styleUrls: ['./order-list-infinite-staff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListInfiniteStaffComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<Order>>;

  constructor(public orderPagingStaffService: OrderPagingStaffService) {}
}
