import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { InventoryReplenishmentChannelPagingService } from '@ts/inventory/replenishment/channel/list/data-access';
import { InventoryReplenishmentChannel } from '@ts/inventory/replenishment/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-inventory-replenishment-channel-list-infinite',
  templateUrl: './inventory-replenishment-channel-list-infinite.component.html',
  styleUrls: ['./inventory-replenishment-channel-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryReplenishmentChannelListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<
    ActionTemplateContext<InventoryReplenishmentChannel>
  >;

  constructor(
    public inventoryReplenishmentChannelPagingService: InventoryReplenishmentChannelPagingService,
  ) {}
}
