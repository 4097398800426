import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Order } from '@ts/order/shared/util-core';

@Component({
  selector: 'ts-order-select-staff-modal',
  templateUrl: './order-select-staff-modal.component.html',
  styleUrls: ['./order-select-staff-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSelectStaffModalComponent {
  @Input() initial?: Order;

  constructor(private modalController: ModalController) {}

  orderSelected(order: Order | null) {
    this.modalController.dismiss({ order });
  }
}
