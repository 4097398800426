import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { InventoryReplenishmentChannel } from '@ts/inventory/replenishment/shared/util-core';

@Component({
  selector: 'ts-inventory-replenishment-channel-select-modal',
  templateUrl: './inventory-replenishment-channel-select-modal.component.html',
  styleUrls: ['./inventory-replenishment-channel-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryReplenishmentChannelSelectModalComponent {
  @Input() initial?: InventoryReplenishmentChannel;

  constructor(private modalController: ModalController) {}

  inventoryReplenishmentChannelSelected(
    inventoryReplenishmentChannel: InventoryReplenishmentChannel | null,
  ) {
    this.modalController.dismiss({ inventoryReplenishmentChannel });
  }
}
