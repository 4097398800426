<tr *ngFor="let receiving of receivings">
  <td>
    <div>{{ receiving.receipt_number }}</div>
    <ts-guid-ui [guid]="receiving.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="receiving"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <div>
      Penagih:
      <ts-user-mention-ui [user]="receiving.charger"></ts-user-mention-ui>
    </div>
    <div>
      Perusahaan:
      <ts-supplier-mention-ui
        [supplier]="receiving.supplier"
      ></ts-supplier-mention-ui>
    </div>
  </td>
  <td>
    <ts-money-ui [money]="receiving.amount_total"></ts-money-ui>
  </td>
  <td>
    <div>
      Tanggal Diterima:
      <ts-timestamp-ui
        [timestamp]="receiving.received_date_timestamp"
      ></ts-timestamp-ui>
    </div>
    <div>
      Tanggal dapat Ditagih:
      <ts-timestamp-ui
        [timestamp]="receiving.chargeable_date_timestamp"
      ></ts-timestamp-ui>
    </div>
    <div *ngIf="receiving.charged_date">
      Tanggal Ditagih:
      <ts-timestamp-ui [timestamp]="receiving.charged_date"></ts-timestamp-ui>
    </div>
  </td>
  <td>
    {{ receiving.status }}
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: receiving }"
    ></ng-template>
  </td>
</tr>
