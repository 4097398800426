<table>
  <thead>
    <ts-order-table-header-staff-ui></ts-order-table-header-staff-ui>
  </thead>
  <tbody>
    <ts-order-table-body-staff-ui
      [orders]="orders"
      [actionTemplate]="actionTemplate"
    ></ts-order-table-body-staff-ui>
  </tbody>
</table>
