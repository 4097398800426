import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Order } from '@ts/order/shared/util-core';

@Component({
  selector: 'ts-order-mention-ui',
  templateUrl: './order-mention-ui.component.html',
  styleUrls: ['./order-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderMentionUiComponent {
  @Input() order!: Order;
}
