<ts-pagination-infinite-table
  [pagingService]="supplierPurchasingMetaPagingService"
>
  <ts-purchasing-supplier-purchasing-meta-table-header-ui
    slot="header"
  ></ts-purchasing-supplier-purchasing-meta-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-purchasing-supplier-purchasing-meta-table-body-ui
      [supplierPurchasingMetas]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-purchasing-supplier-purchasing-meta-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
