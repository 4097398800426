import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SupplierPurchasingMeta } from '@ts/purchasing/shared/util-core';

@Component({
  selector: 'ts-purchasing-supplier-purchasing-meta-mention-ui',
  templateUrl:
    './purchasing-supplier-purchasing-meta-mention-ui.component.html',
  styleUrls: [
    './purchasing-supplier-purchasing-meta-mention-ui.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingSupplierPurchasingMetaMentionUiComponent {
  @Input() supplierPurchasingMeta!: SupplierPurchasingMeta;
}
