import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { InventoryReplenishmentChannel } from '@ts/inventory/replenishment/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-inventory-replenishment-channel-table-body-ui',
  templateUrl: './inventory-replenishment-channel-table-body-ui.component.html',
  styleUrls: ['./inventory-replenishment-channel-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryReplenishmentChannelTableBodyUiComponent {
  @Input()
  inventoryReplenishmentChannels!: readonly InventoryReplenishmentChannel[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<
    ActionTemplateContext<InventoryReplenishmentChannel>
  >;
}
