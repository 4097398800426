<tr
  *ngFor="let inventoryReplenishmentChannel of inventoryReplenishmentChannels"
>
  <td>
    <div>{{ inventoryReplenishmentChannel.title }}</div>
    <ts-guid-ui [guid]="inventoryReplenishmentChannel.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="inventoryReplenishmentChannel"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <div>
      Awal:
      <ts-facility-zone-mention-ui
        [facilityZone]="inventoryReplenishmentChannel.facility_zone_origin"
      ></ts-facility-zone-mention-ui>
    </div>
    <div>
      Tujuan:
      <ts-facility-zone-mention-ui
        [facilityZone]="inventoryReplenishmentChannel.facility_zone_destination"
      ></ts-facility-zone-mention-ui>
    </div>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: inventoryReplenishmentChannel }"
    >
    </ng-template>
  </td>
</tr>
