import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { OrderMentionUiComponent } from './order-mention-ui/order-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [OrderMentionUiComponent],
  exports: [OrderMentionUiComponent],
})
export class OrderUiMentionModule {}
