<tr *ngFor="let returnToSupplier of returnToSuppliers">
  <td>
    <div>{{ returnToSupplier.title }}</div>
    <ts-guid-ui [guid]="returnToSupplier.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="returnToSupplier"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <div *ngIf="returnToSupplier.facility_zone.facility">
      Fasilitas:
      <ts-facility-mention-ui
        [facility]="returnToSupplier.facility_zone.facility"
      ></ts-facility-mention-ui>
    </div>
    <div>
      Zona Fasilitas:
      <ts-facility-zone-mention-ui
        [facilityZone]="returnToSupplier.facility_zone"
      ></ts-facility-zone-mention-ui>
    </div>
  </td>
  <td>
    <ts-money-ui [money]="returnToSupplier.amount_total"></ts-money-ui>
  </td>
  <td>
    {{ returnToSupplier.status }}
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: returnToSupplier }"
    ></ng-template>
  </td>
</tr>
