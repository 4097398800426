import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { SupplierPurchasingMeta } from '@ts/purchasing/shared/util-core';

@Component({
  selector: 'ts-purchasing-supplier-purchasing-meta-select-modal',
  templateUrl:
    './purchasing-supplier-purchasing-meta-select-modal.component.html',
  styleUrls: [
    './purchasing-supplier-purchasing-meta-select-modal.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingSupplierPurchasingMetaSelectModalComponent {
  @Input() initial?: SupplierPurchasingMeta;

  constructor(private modalController: ModalController) {}

  supplierPurchasingMetaSelected(
    supplierPurchasingMeta: SupplierPurchasingMeta | null,
  ) {
    this.modalController.dismiss({ supplierPurchasingMeta });
  }
}
