import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { Order } from '@ts/order/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-order-table-staff-ui',
  templateUrl: './order-table-staff-ui.component.html',
  styleUrls: ['./order-table-staff-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTableStaffUiComponent {
  @Input() orders!: readonly Order[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<Order>
  >;
}
